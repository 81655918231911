import React from "react";
import { Typography } from "@mui/material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { FAQandAnswers } from "./constant";

const FAQs = () => {
  return (
    <div style={{ padding: "50px", gap: "30px", background: "#EBEBEB" }}>
      <div 
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
      }}>
        <div style={{maxWidth:'1280px'}}>
          <Typography
            sx={{
              fontFamily: "Bebas Neue",
              fontWeight: 400,
              fontSize: "80px",
              lineHeight: "96px",
              color: "#3d3d47",
              padding: "12px 0px",
              textAlign: "center",
            }}
          >
            Frequently Asked questions
          </Typography>

          <div>
            {FAQandAnswers.map((faq, index) => {
              return (
                <div key={index} style={{ paddingBottom: "22px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 700,
                      fontSize: "23px",
                      lineHeight: "20px",
                      color: "#3d3d47",
                      padding: "6px 0px 12px 0px",
                      display: "flex",
                    }}
                  >
                    <span style={{ marginRight: "8px" }}>
                      <HelpRoundedIcon color="success" />{" "}
                    </span>
                    {"  "}
                    {faq.question}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 400,
                      fontSize: "20px",
                      lineHeight: "29px",
                      color: "#3d3d47",
                      padding: "0px 0px 6px 34px",
                    }}
                  >
                    {faq.answer}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div style={{ paddingBottom: "22px" }}>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: "23px",
                lineHeight: "20px",
                color: "#3d3d47",
                padding: "6px 0px 12px 0px",
                display: "flex",
              }}
            >
              <span style={{ marginRight: "8px" }}>
                <HelpRoundedIcon color="success" />{" "}
              </span>
              {"  "}What do I do if I need help?
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 400,
                fontSize: "20px",
                lineHeight: "29px",
                color: "#3d3d47",
                padding: "0px 0px 6px 34px",
              }}
            >
              For questions about your billing or accessing the course
              materials, email <br />
              help@createfromessence.com. If you have a question about how you
              put the course methods to work in your own business, we encourage
              you to ask those in the Create from Essence Community or at
              Peter’s Office Hours.
            </Typography>
          </div>

          <Typography
            sx={{
              fontFamily: "Lato",
              fontWeight: 400,
              fontSize: "17px",
              lineHeight: "29px",
              color: "#3d3d47",
              padding: "12px 0px 12px 0px",
              textAlign: "center",
            }}
          >
            Haven’t got your answer?{" "}
            <a
              href="mailto:help@createfromessence.com"
              style={{ textDecoration: "none", color: "#6879D5" }}
            >
              Contact our support now
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
