import ReactPlayer from "react-player";
import React from "react";
import { Box, styled, Typography, Grid, Button } from "@mui/material";
import "../styles.css";

const HeaderMobileView = props => {
  const BoxText = styled(Box)(({ theme }) => ({
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <div className="header-background">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "64px 30px 30px 30px",
        }}
        component="header"
      >
        <div className="header-logo" style={{display: 'flex', justifyContent:'center',alignItems:'center' }}>
          <img
            style={{ width: "90%" }}
            src={require("../../assets/Create From Essence Logo_Transparent with box.png")}
            alt="amazon"
          />
        </div>
        <Grid container>
          <Grid className="header-grid-section" item xs={12} lg={6}>
            <BoxText component="section">
              {/* <VerticalCarousel slides={slides} interval={4000} /> */}
              <Typography
                variant="h4"
                component="h3"
                sx={{
                  fontWeight: 400,
                  fontFamily: "Bebas Neue",
                  fontSize: "48px",
                  lineHeight: "57px",
                  color: "#fff",
                }}
              >
                CREATE A BUSINESS YOU LOVE <br />
                WITH IMPACT AND PURPOSE
              </Typography>
            </BoxText>
            <BoxText component="section">
              <Typography
                sx={{
                  paddingTop: "12px",
                  fontWeight: 400,
                  fontSize: "24px",
                  lineHeight: "28px",
                  fontFamily: "LATO",
                  color: "#fff",
                }}
              >
                Don't just chase other people's dream or what they think <br />{" "}
                you are supposed to do.
              </Typography>
            </BoxText>
            <BoxText component="section">
              <Typography
                sx={{
                  paddingTop: "12px",
                  fontWeight: 400,
                  fontSize: "24px",
                  lineHeight: "28px",
                  fontFamily: "LATO",
                  color: "#fff",
                }}
              >
                Create a business that is deeply resonant <br /> with who you
                are.
              </Typography>
            </BoxText>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            style={{ color: "white", paddingTop: "42px" }}
          >
            <div className="just-reflect-video-box">
              <ReactPlayer
                width="100%"
                height="200px"
                controls={true}
                url="https://www.dropbox.com/scl/fi/g847y63058grlyy4tgk3q/LP-Intro-Des.mp4?rlkey=ig45w6f3gkt34jdsukivbjm6i&dl=0"
              />
            </div>
          </Grid>
        </Grid>
        <Typography
          variant="h4"
          component="h3"
          sx={{
            fontWeight: 700,
            color: "#3d3d47",
            margin: "28px 0px",
            padding: "12px 0px",
            textAlign: "center",
          }}
        >
          <span>
            <Button
              style={{
                border: "1px solid #f09711",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "28.8px",
                fontFamily: "Lato",
                width: "338px",
                height: "72px",
                color: "#000000",
                background: "#f09711",
                borderRadius: "5px",
                padding: "0px 24px",
                alignItems: "center",
              }}
              onClick={()=>props.handleScrollSection('section2')}
            >
              Start today
            </Button>
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default HeaderMobileView;
