import React, {useState} from "react";
import { Box, Stack, styled, Link } from "@mui/material";
import FooterTitle from "./FooterTitle";
import TermsOfUsage from "../TermsOfUsageModal";

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [docType, setDocType] = useState('')
  
  const handleOpen = (type) => {
    setOpen(true);
    setDocType(type)
  }
  const handleClose = () => setOpen(false);
  const StackColumn = styled(Stack)(() => ({
    alignItems: "start",
    flex: 1,
    gap: 8,
    textAlign: "start",
    color: "#fff",
  }));

  const BoxRow = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#2DB2EC",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 20,
    },
  }));

  
  
  

  return (
    <BoxRow
      component="footer"
      sx={{
        py: 4,
        px: 2,
      }}
    >

      <StackColumn>
        <FooterTitle text={"Create from essence, Copyright 2024"} />
      </StackColumn>
      <div>
      {open && <TermsOfUsage docType={docType} open={open} handleClose={handleClose} />}
        <Link
          onClick={()=> handleOpen('tnc')}
          //target="_blank"
          rel="noreferrer"
          style={{
            color: "#fff",
            marginRight: "12px",
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19.2px",
          }}
        >
          <u>Terms of Usage</u>
        </Link>
        <Link
          onClick={()=> handleOpen('privacy')}
          //target="_blank"
          rel="noreferrer"
          style={{
            color: "#fff",
            marginRight: "12px",
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19.2px",
          }}
        >
          <u>Privacy policy</u>
        </Link>
      </div>
    </BoxRow>
  );
};

export default Footer;
