import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  Stack,
  Typography,
  styled,
  ListItemButton,
  ListItemText,
} from "@mui/material";
// menu
import DrawerItem from "./DrawerItem";
//components
import FooterLink from "../components/Footer/FooterLink";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import './styles.css'

// personalization
const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const ListMenu = styled(List)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const itemList = [
//   {
//     text: "Home",
//     to: "/",
//   },
  // {
  //   text: "About",
  //   to: "/about"
  // },
  // {
  //   text: "Coaching",
  //   to: "/contact",
  // },
  // {
  //   text: "Blogs",
  //   to: "/",
  // },
];

const Navbar = () => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0)
  }
  return (
    <AppBar
      component="nav"
      position="sticky"
      sx={{
        backgroundColor: "#3d3d47",
      }}
      elevation={0}
    >
      <StyledToolbar>
        <Typography style={{maxHeight:'100px', width:'220px'}} variant="h6" component="h2" fontStyle="helvetica">
          <Link onClick={handleLinkClick} to="/">
            <img
                style={{ height:'100%', width:'100%'}}
                src={require("../assets/Create From Essence Logo_Blue Font Transparent.png")}
                alt="amazon"
              />
          </Link>
        </Typography>
        <Box sx={{ display: { xs: "none", sm: "none" } }}>
          <DrawerItem />
        </Box>
        <ListMenu>
          {/* {itemList.map((item) => {
            const { text } = item;
            return (
              <ListItem key={text}>
                <ListItemButton
                  component={Link}
                  to={item.to}
                  sx={{
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#60d7f7",
                    },
                  }}
                >
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            );
          })} */}
          <ListItem>
          <Stack
            direction="row"
            width="120px"
            maxWidth="100%"
            justifyContent="space-between"
          >
            <a
              href="https://www.instagram.com/createfromessence/"
              variant="body2"
              rel="noreferrer"
              className="fb-link-btn"
              target="_blank"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61554434816734"
              variant="body2"
              rel="noreferrer"
              className="fb-link-btn"
              target="_blank"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/cqsavvy/"
              target="_blank"
              rel="noreferrer"
              variant="body2"
              className="fb-link-btn"
            >
              <LinkedInIcon />
            </a>
          </Stack>
        </ListItem>
        </ListMenu>
        
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;
