import React, { useState, useRef } from "react";
import { Typography } from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import "../components/styles.css";

const Curriculum = ({ children }) => {
  const [openSections, setOpenSections] = useState({});
  const contentRef = useRef(null);

  const handleToggle = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const collapseContent = [
    {
      title: "Module 1: Begin Before You Are Ready",
      content: (
        <span>
          <span>
            Introduction to the foundational tools to create a bold future with
            success, meaning, and purpose.
          </span>
          <br />
          <br />
          Learn about your essence, and how to create from the future instead of
          recreating the past.Challenge your mind to expand what you believe is
          possible for you and get tactical insight into choosing your next
          steps.
        </span>
      ),
    },
    {
      title: "Module 2: Learn How to Quest",
      content:
        "Go deeper into the best strategies to progress on your quest. You will learn advanced strategies around experimentation, creating opportunities for the discovery of unknown opportunities by optimizing for serendipity.",
    },
    {
      title: "Module 3: A Leader’s Mindset",
      content: (
        <span>
          <span>
            Develop and strengthen your leader’s mindset by recognizing and
            powering through limiting beliefs, reframing failure, and making the
            imposter symptom your ally.
          </span>
          <br />
          <br />
          Refine your ability to choose great steps forward.
        </span>
      ),
    },
    {
      title: "Module 4: A Leader’s Toolkit",
      content:
        "Build out your toolkit for better leadership and decision-making. Learn how to build a future-friendly high-performance culture by raising your emotional and culture intelligence as well as your communication and how to keep yourself and others accountable.",
    },

    {
      title: "Bonus Module: Building a Powerful Network",
      content: (
        <span>
          <span>
            Build a vast and strong network of exceptional, diverse, and
            talented people who will give you access to exclusive opportunities
            beyond what you could have dreamed of.
          </span>
          <br />
          It’s never too late to build a powerful network, and I’ll show you how
          to do it, step by step.
        </span>
      ),
    },
  ];

  return (
    <div style={{ padding: "50px", gap: "30px", background: "#FFFFFF" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "400 !important",
            fontSize: "80px",
            lineHeight: "96px",
            fontFamily: "Bebas Neue",
            textAlign: "center",
            paddingBottom: "24px",
          }}
        >
          MASTERCLASS CURRICULUM
        </Typography>
        <div style={{ maxWidth: "1280px" }} className="collapse-container">
          {collapseContent.map((content, index) => {
            //const expandedIndex = openIndex.length&& openIndex.find(oind => oind === index)
            //console.log('expandedIndex', expandedIndex)
            return (
              <div key={index} style={{ marginBottom: "12px" }}>
                <div
                  className={
                    openSections[index]
                      ? "collapse-title-expand"
                      : "collapse-title-collapse"
                  }
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "22px",
                      fontFamily: "Lato",
                      textAlign: "center",
                    }}
                  >
                    {content.title}
                  </Typography>
                  <span style={{ paddingTop: "4px" }}>
                    {openSections[index] ? (
                      <span onClick={() => handleToggle(index)}>
                        <ArrowCircleUpIcon />
                      </span>
                    ) : (
                      <span onClick={() => handleToggle(index)}>
                        <ArrowCircleDownIcon color="info" />
                      </span>
                    )}
                  </span>
                </div>
                {openSections[index] && (
                  <div className="collapse-content" ref={contentRef}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "20px",
                        color: "#00000",
                        fontFamily: "Lato",
                        textAlign: "center",
                      }}
                    >
                      {content.content}
                    </Typography>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Curriculum;
