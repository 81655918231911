import { Typography } from "@mui/material";
import Gallery from "./Gallery";
import "./styles.css";


const TestimonailsNew = () => {

  return(
    <div style={{padding:'30px 30px 0px 30px', gap:'30px'}}>
      <Typography
          sx={{
            fontWeight: 400,
            fontSize:'76px',
            lineHeight:'96px',
            color: "black",
            fontFamily:'Bebas Neue',
            textAlign: "center",
          }}
        >
          TESTIMONIALS
        </Typography>
        <Gallery />
    </div>
    
  )
}

export default TestimonailsNew