import React from 'react'
import {  
    Box,
    Button,
    Stack,
    TextField
} from '@mui/material'
import Title from './Title'
import Paragraph from './Paragraph'
import axios from 'axios'

const Details = () => {

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const inputData = {
            Name: data.get('fullname'),
            Email: data.get('email')
        }

        axios.post('https://sheet.best/api/sheets/5505d2d6-8988-43ab-98c2-b47c38419046',inputData).then((response)=> {
            window.location.reload()
        })
    }


    return (
        <Stack 
        component='section'
        direction="column"
        justifyContent= 'center'
        alignItems='center'
        sx={{
            py: 10,
            px: 2,
            fontWeight: 700,
            fontFamily: "montserrat",
        }}
        >
            <Title 
            text={
                'START YOUR QUEST'
                } 
            textAlign={'center'}
            />
            <Paragraph 
            text={
                'Receive ideas to ignite your quest on #CREATEfromESSENCE'
            }
            maxWidth = {'sm'}
            mx={0}
            textAlign={'center'}
            />

            <Box 
            component="form"
            noValidate 
            onSubmit={handleSubmit} 
            sx={{ 
                mt: 1,
                py: 2
            }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="fullname"
                    label="Full name"
                    type="fullname"
                    id="fullname"
                />
                <Button 
                variant="contained" 
                fullWidth
                type="submit"
                size="medium"
                sx= {{ 
                    fontSize: '0.9rem',
                    textTransform: 'capitalize', 
                    py: 2,
                    mt: 3, 
                    mb: 2,
                    borderRadius: 0,
                    backgroundColor: '#14192d',
                    "&:hover": {
                        backgroundColor: '#1e2a5a',
                    }
                }}
                >
                    get on board
                </Button>
            </Box>
        </Stack>
    )
}

export default Details;