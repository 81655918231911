import { Grid, Typography } from "@mui/material";
import React from "react";

const AboutPeterKovacs = () => {
  return (
    <div className="about-peter-container">
      <div  
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Grid container className="about-peter-grid">
          <Grid style={{display:'flex', justifyContent:'center'}} item xs={12} md={5} lg={5}>
            <div style={{ height: "100%", width: "90%", paddingTop:'20px'}}>
              <img
                width="100%"
                style={{ maxHeight: "860px" }}
                src={require("../assets/peter-kovacs-new-head-shot.jpg")}
                alt="about-peter-kovacs"
              />
            </div>
          </Grid>
          <Grid style={{display:'flex', justifyContent:'center', gap: "34px"}} item xs={12} md={6} lg={6}>
            <div className='about-peter-covacs-section'>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontWeight: 900,
                  fontSize: "60px",
                  lineHeight: "76.8px",
                  color: "#3d3d47",
                  padding: "6px 0px",
                }}
              >
                ABOUT PETER KOVACS
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontWeight: 900,
                  fontSize: "28px",
                  lineHeight: "33.6px",
                  color:'#00000'
                }}
              >
                Executive Coach to Founders and High Achievers
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  color: "#3d3d47",
                  fontWeight: 600,
                  padding: "12px 0px",
                }}
              >
                I am an Executive Coach, helping high performers, entrepreneurs, change-makers, 
                and C-level executives raise the game for their companies, careers, and impact. 
                I have worked with exceptional leaders at Amazon, NVIDIA, and Bain & Co.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  color: "#3d3d47",
                  fontWeight: 600,
                  padding: "12px 0px",
                }}
              >
                I founded four tech startups with one successful exit. 
                I speak about leadership and entrepreneurship on global stages, 
                including Davos, Switzerland, during the World Economic Forum. 
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  color: "#3d3d47",
                  fontWeight: 600,
                  padding: "12px 0px",
                }}
              >
                Coaching and serving leaders have been among the greatest joys of my life, 
                and it’s what’s most congruent with my Essence. 
                I am looking forward to supporting you on your journey, too.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  color: "#3d3d47",
                  fontWeight: 600,
                  padding: "10px 0px",
                }}
              >
                I am looking forward to supporting you on your journey. I can’t wait
                to meet you!
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
    
  );
};

export default AboutPeterKovacs;
