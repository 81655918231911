import { Grid, Typography } from "@mui/material";
import React from "react";
import WhoIsItForMobileView from "./mobileViewComponents/WhoIsItForSectionMobileView";
import "./styles.css";

const WhoIsItFor = () => {
  let width = window.innerWidth;

  return width > 480 ? (
    <div
      className="who-is-for-section"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "80px",
          lineHeight: "96px",
          fontFamily: "Bebas Neue",
          textAlign: "center",
        }}
      >
        WHO IS THIS MASTERCLASS FOR ?
      </Typography>
      <Typography
        sx={{
          fontSize: "32px",
          fontFamily: "Lato",
          fontWeight: 400,
          textAlign: "center",
          lineHeight: "38px",
        }}
      >
        Driven Leaders pursuing impact and growth
      </Typography>
      <Grid
        justifyContent="center"
        alignItems="center"
        maxWidth="1200px"
        marginTop="8px"
        container
        spacing={2}
      >
        <Grid className="who-is-it-for-grid-block" item xs={12} md={12} lg={4}>
          <div className="who-is-it-for-blocks">
            <Typography
              variant="h2"
              component="h1"
              sx={{
                color: "#000000",
                fontFamily: "Lato",
                textAlign: "center",
                fontWeight: 700,
                paddingBottom:'34px',
                fontSize: "28px",
                lineHeight: "38px",
              }}
            >
              Entrepreneurs
            </Typography>
            <Typography
              sx={{
                color: "#000000",
                fontFamily: "Lato",
                textAlign: "center",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
              }}
            >
              Get the guidance and help to <br /> avoid common mistakes
            </Typography>
          </div>
        </Grid>
        <Grid className="who-is-it-for-grid-block" item xs={12} md={12} lg={4}>
          <div className="who-is-it-for-blocks">
            <Typography
              sx={{
                color: "#000000",
                fontFamily: "Lato",
                textAlign: "center",
                fontWeight: 700,
                fontSize: "28px",
                lineHeight: "38px",
                paddingBottom:'34px'
              }}
            >
              Post-Exit Founders
            </Typography>
            <Typography
              sx={{
                color: "#000000",
                fontFamily: "Lato",
                textAlign: "center",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
              }}
            >
              Made a big exit but still <br />
              feeling something is missing
            </Typography>
          </div>
        </Grid>
        <Grid className="who-is-it-for-grid-block" item xs={12} md={12} lg={4}>
          <div className="who-is-it-for-blocks">
            <Typography
              sx={{
                color: "#000000",
                fontFamily: "Lato",
                textAlign: "center",
                fontWeight: 700,
                fontSize: "28px",
                paddingBottom:'34px',
                lineHeight: "38px",
              }}
            >
              Seasoned Executives
            </Typography>
            <Typography
              sx={{
                color: "#000000",
                fontFamily: "Lato",
                textAlign: "center",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
              }}
            >
              Levarage your experience <br /> to create a better life
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  ) : (
    <WhoIsItForMobileView />
  );
};

export default WhoIsItFor;
