import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TermsContent from './TermsContent';
import PrivacyPolicyContent from './PrivacyPolicy';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height:630,
  overflow:'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TermsOfUsage = props => {
  

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {props.docType === 'tnc'?<TermsContent />: <PrivacyPolicyContent />}
        </Box>
      </Modal>
    </div>
  );
}

export default TermsOfUsage