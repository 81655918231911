import ReactPlayer from "react-player";
import React from "react";
import { Typography } from "@mui/material";
import "./styles.css";

const JustReflect = () => {
  return (
    <div className="just-reflect-section">
      <Typography
        variant="h2"
        component="h1"
        sx={{
          fontWeight: 400,
          fontFamily:'Bebas Neue',
          fontSize:'80px',
          lineHeight:'96px',
          textAlign: "center",
        }}
      >
        YOUR JOURNEY BEGINS HERE
      </Typography>
      <Typography
        variant="h4"
        component="h3"
        sx={{
          fontFamily:'Lato',
          fontWeight: 400,
          lineHeight:'38.4px',
          textAlign: "center",
        }}
      >
        Take the first three steps and get a taste of what awaits inside
      </Typography>
      <div className="just-reflect-video-container">
        <div className="just-reflect-video-box">
          <ReactPlayer
            width="100%"
            height="200px"
            controls={true}
            url="https://www.dropbox.com/scl/fi/bip5g281mkyna4rek1cti/LP-Vid-1-Quest-Des.mp4?rlkey=3p7vps149qw215km7mvnpbsa6&dl=0"
          />
          <Typography
            sx={{
              fontFamily:'Lato',
              fontSize: "28px",
              fontWeight: 700,
              textAlign: "center",
              lineHeight:'33.6px',
            }}
          >
            Are you creating from the Future?
          </Typography>
          <Typography
            sx={{
              fontFamily:'Lato',
              fontSize: "20px",
              fontWeight: 400,
              textAlign: "center",
              lineHeight:'24px',
            }}
          >
            Or are you recreating the past?
          </Typography>
        </div>
        <div className="just-reflect-video-box">
          <ReactPlayer
            width="100%"
            height="200px"
            controls={true}
            url="https://www.dropbox.com/scl/fi/1s1b52byv0lfbu2l4ps2s/LP-Vid-2-Bottles-Des.mp4?rlkey=uf2ljsmbkjcp25jf1x6kybmov&dl=0"
          />
          <Typography
            sx={{
              fontFamily:'Lato',
              fontSize: "28px",
              fontWeight: 700,
              textAlign: "center",
              lineHeight:'33.6px',
            }}
          >
            Are you stuck in a Bottle?
          </Typography>
          <Typography
            sx={{
              fontFamily:'Lato',
              fontSize: "20px",
              fontWeight: 400,
              textAlign: "center",
              lineHeight:'24px',
            }}
          >
            How do you know if it’s true?
          </Typography>
        </div>
        <div className="just-reflect-video-box">
          <ReactPlayer
            width="100%"
            height="200px"
            controls={true}
            url="https://www.dropbox.com/scl/fi/mmq3figp5w5pw3n92rnak/LP-Vid-3-Dominoes-Des-Audio-levels-fixed.mp4?rlkey=czkisyrpx26hkp4lbzgt8e5x5&dl=0"
          />
          <Typography
            sx={{
              fontFamily:'Lato',
              fontSize: "28px",
              fontWeight: 700,
              textAlign: "center",
              lineHeight:'33.6px',
            }}
          >
            Knocking over the Empire State Building
          </Typography>
          <Typography
            sx={{
              fontFamily:'Lato',
              fontSize: "20px",
              fontWeight: 400,
              textAlign: "center",
              lineHeight:'24px',
            }}
          >
            With 29 dominoes.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default JustReflect;
