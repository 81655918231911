import { Grid, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import React from "react";
import "./styles.css";

const TasteOfCourse = () => {
  return (
    <div className="Taste-Of-Course-section">
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Grid container spacing={2} style={{maxWidth:'1300px'}}>
          <Grid
            className="what-you-will-learn-section"
            item
            xs={12}
            md={12}
            lg={6}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "37px",
                lineHeight: "48px",
                fontWeight: 900,
                paddingLeft: "18px",
              }}
            >
              WHAT YOU WILL LEARN
            </Typography>

            <List className="Taste-Of-Masterclass_results">
              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <AutoAwesomeRoundedIcon
                    style={{
                      fontSize: "28px",
                      color: "white",
                      padding: "4px",
                      background: "#0074DF",
                      border: "1px solid #0074DF",
                      borderRadius: "49%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato ",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    CREATING FROM THE FUTURE
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    Go beyond the levels of performance you already know are
                    possible.
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <AutoAwesomeRoundedIcon
                    style={{
                      fontSize: "28px",
                      color: "white",
                      padding: "4px",
                      background: "#0074DF",
                      border: "1px solid #0074DF",
                      borderRadius: "49%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    IMPROVE YOUR LEADERSHIIP SKILLS
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    Develop the ability to lead in challenging and {" "}
                    ambiguous situations
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <AutoAwesomeRoundedIcon
                    style={{
                      fontSize: "28px",
                      color: "white",
                      padding: "4px",
                      background: "#0074DF",
                      border: "1px solid #0074DF",
                      borderRadius: "49%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    OVERCOME LIMITING BELIEFS
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    Understand your limiting beliefs and how to get past them
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <AutoAwesomeRoundedIcon
                    style={{
                      fontSize: "28px",
                      color: "white",
                      padding: "4px",
                      background: "#0074DF",
                      border: "1px solid #0074DF",
                      borderRadius: "49%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    STRATEGIC THINKING
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    Know how to shift your energy from being reactive to {" "}
                    strategic
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <AutoAwesomeRoundedIcon
                    style={{
                      fontSize: "28px",
                      color: "white",
                      padding: "4px",
                      background: "#0074DF",
                      border: "1px solid #0074DF",
                      borderRadius: "49%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    OPTIMIZE FOR SERENDIPITY
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    Discover opportunities you didn’t know existed
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <AutoAwesomeRoundedIcon
                    style={{
                      fontSize: "28px",
                      color: "white",
                      padding: "4px",
                      background: "#0074DF",
                      border: "1px solid #0074DF",
                      borderRadius: "49%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    EMBRACE YOUR IMPOSTER SYNDROME
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    Turn your imposter syndrome into a signal that you are
                    aiming big enough
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <AutoAwesomeRoundedIcon
                    style={{
                      fontSize: "28px",
                      color: "white",
                      padding: "4px",
                      background: "#0074DF",
                      border: "1px solid #0074DF",
                      borderRadius: "49%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    LEVERAGE YOUR NETWORK
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    Build a powerful network with meaningful connections that
                    can feed you exclusive opportunities
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>

          <Grid className="results-section" item xs={12} md={12} lg={6}>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "37px",
                lineHeight: "48px",
                fontWeight: 900,
              }}
            >
              WHAT YOUR RESULTS WILL BE
            </Typography>

            <List className="Taste-Of-Masterclass_results">
              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <CheckCircleRoundedIcon
                    color="success"
                    style={{ fontSize: "41px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    A FRESH NEW PERSPECTIVE
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    A new, more powerful way of seeing the world
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <CheckCircleRoundedIcon
                    color="success"
                    style={{ fontSize: "41px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    UNDERSTANDING YOUR ESSENCE
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    Know when and how to be at your best. How to be in the zone
                    more often. Effortlessly. 
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <CheckCircleRoundedIcon
                    color="success"
                    style={{ fontSize: "41px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    A POWERFUL NETWORK
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    Strong connections you can leverage for exclusive
                    opportunities
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <CheckCircleRoundedIcon
                    color="success"
                    style={{ fontSize: "41px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    PERSONAL TRANSFORMATION
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    What got you here won’t get you there. Become who you are
                    meant to be.
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <CheckCircleRoundedIcon
                    color="success"
                    style={{ fontSize: "41px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    HEIGHTENED CONFIDENCE
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    In the journey knowing you’ll overcome any challenge
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <CheckCircleRoundedIcon
                    color="success"
                    style={{ fontSize: "41px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    LEADER'S TOOLKIT
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    The skills to face any challenge on your 
                    quest
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className="taste-of-course-list-item" alignItems="flex-start">
                <ListItemIcon alignItems="flex-start">
                  <CheckCircleRoundedIcon
                    color="success"
                    style={{ fontSize: "41px" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: 900,
                    }}
                  >
                    AN ALLY FOR THE JOURNEY
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "#454545",
                    }}
                  >
                    I’m here to support you on your journey, wherever it may{" "}
                    take you.
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TasteOfCourse;
