import { Grid, Typography } from "@mui/material";
import React from "react";

const Companies = () => {
  return (
    <div className="company-container" style={{ display: "flex", justifyContent: "center", alignItems:'center', flexDirection: 'column'}}>
      <Typography
        sx={{
          fontFamily:'Bebas Neue',
          fontWeight: 400,
          fontSize:'40px',
          lineHeight: '48px',
          color: "#14213d",
          textAlign: "center",
        }}
      >
        TRUSTED BY TOP LEADERS FROM
      </Typography>
      <Grid container style={{ padding: "12px", maxWidth:'1200px' }}>
        <Grid className="company-grid-item" item xs={12} md={3} lg={3}>
          <div style={{width: '40%', paddingBottom:'18px'}}>
            <img
              width="100%"
              src={require("../assets/amazon_logo_dark.png")}
              alt="amazon"
            />
          </div>
        </Grid>
        <Grid className="company-grid-item" item xs={12} md={3} lg={3}>
          <div style={{width: '40%', paddingBottom:'18px'}}>
            <img
              width="100%"
              src={require("../assets/nvidia_logo-dark.png")}
              alt="amazon"
            />
          </div>
        </Grid>
        <Grid className="company-grid-item" item xs={12} md={3} lg={3}>
          <div style={{width: '40%', paddingBottom:'18px'}}>
            <img
              width="100%"
              src={require("../assets//bain_logo_dark.png")}
              alt="amazon"
            />
          </div>
        </Grid>
        
        {/* <Grid className="company-grid-item" item xs={12} md={3} lg={3}>
          <div style={{width: '40%'}}>
            <img
              width="100%"
              src={require("../assets/Nasdaq_Logo.png")}
              alt="amazon"
            />
          </div>
        </Grid> */}
          <Grid className="company-grid-item" item xs={12} md={3} lg={3}>
            <div style={{width: '40%', paddingBottom:'18px'}}>
              <img
                width="100%"
                src={require("../assets/singularity_logo_dark.png")}
                alt="amazon"
              />
            </div>
          </Grid>
        </Grid>
        <Typography
        sx={{
          fontWeight: 400,
          fontFamily:'Lato',
          fontSize:'24px',
          lineHeight:'28px',
          color: "#14213d",
          textAlign: "center",
        }}
      >
        Served over <span style={{fontWeight:700}}>1000</span>{' '} CEOs, Founders, VCs, Leaders, and Companies
      </Typography>
    </div>
  );
};

export default Companies;
