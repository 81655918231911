import { Grid, Typography, Card, CardHeader, CardContent } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import React from "react";

const PricingCard = () => {
  return (
    <div
      className="pricing-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      id="section2"
    >
      <Typography sx={{
            fontWeight: "400 !important",
            fontSize: "80px",
            lineHeight: "96px",
            fontFamily: "Bebas Neue",
            textAlign: "center",
            paddingBottom:'24px'
          }}>
            EVERYTHING YOU NEED <br />
            TO CREATE FROM <span style={{color:"#FFFFFF"}}>YOUR</span> ESSENCE
          </Typography>
      <Grid container>
        <Grid className="pricing-card-grid" item xs={12} md={12} lg={12}>
          <Card className="pricing-card">
            <CardHeader
              title={
                <Typography
                  sx={{
                    fontWeight: "900 !important",
                    background: "#EDEDED",
                    fontSize: "26px",
                    lineHeight: "31px",
                    fontFamily: "Lato",
                    textAlign: "center",
                  }}
                >
                  <span style={{ color: "#1696CD" }}>CREATE FROM ESSENCE</span>{" "}
                  <br /> <span style={{ color: "#6F6F6F" }}>MASTERCLASS</span>
                </Typography>
              }
              sx={{
                fontWeight: "900 !important",
                background: "#EDEDED",
                fontSize: "26px",
                lineHeight: "31px",
                fontFamily: "Lato",
                textAlign: "center",
              }}
            />
            <hr />
            <div style={{ paddingTop: "15px" }} className="pricing-amount">
              <Typography sx={{ fontWeight: 700, fontSize: "70px" }}>
                $67
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontFamily: "Lato",
                  marginTop: "60px",
                }}
              >
                /month
              </Typography>
            </div>
            <CardContent className="pricing-card-content">
              <Typography
                sx={{
                  color: "#14213d",
                  fontFamily: "montserrat",
                }}
                variant="body2"
                color="text.secondary"
              >
                {/* <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "#3d3d47",
                    padding: "0px 0px 12px 0px",
                  }}
                >
                  What's included?
                </Typography> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "19px",
                        lineHeight: "22px",
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      <span style={{ marginRight: "4px", marginTop:'2px' }}>
                        <CheckCircleRoundedIcon
                          color="success"
                          sx={{ fontSize: "20px" }}
                        />
                      </span>{" "}
                      Self-Guided Masterclass
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "19px",
                        lineHeight: "22px",
                        fontWeight: 400,
                        color: "#6F6F6F",
                        marginLeft: "28px",
                        paddingBottom:'12px'
                      }}
                    >
                      Learn at your own pace, get challenged, and grow as a
                      leader and entrepreneur.{" "}
                      <span style={{ fontWeight:700, color: "#6F6F6F" }}>
                        New content is released every two weeks.
                      </span>
                    </Typography>
                  </div>
                  <div sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "19px",
                        lineHeight: "22px",
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      <span style={{ marginRight: "4px" }}>
                        <CheckCircleRoundedIcon
                          color="success"
                          sx={{ fontSize: "20px" }}
                        />
                      </span>{" "}
                      Community Access
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "19px",
                        lineHeight: "22px",
                        fontWeight: 400,
                        color: "#6F6F6F",
                        marginLeft: "28px",
                        paddingBottom:'12px'
                      }}
                    >
                      <span style={{ fontWeight:700, color: "#6F6F6F" }}>
                        Gather your allies!
                      </span>{" "}
                      A community of like-minded leaders and entrepreneurs on
                      the quest to create a bold future.  Get support from{" "}
                      <span style={{ fontWeight:700, color: "#6F6F6F" }}>Peter Kovacs</span> and
                      other members.
                    </Typography>
                  </div>
                  <div sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "19px",
                        lineHeight: "22px",
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      <span style={{ marginRight: "4px" }}>
                        <CheckCircleRoundedIcon
                          color="success"
                          sx={{ fontSize: "20px" }}
                        />
                      </span>{" "}
                      Weekly Office Hours
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "19px",
                        lineHeight: "22px",
                        fontWeight: 400,
                        color: "#6F6F6F",
                        marginLeft: "28px",
                        paddingBottom:'12px'
                      }}
                    >
                      Live access to{" "}
                      <span style={{ fontWeight:700, color: "#6F6F6F" }}>Peter Kovacs</span> to
                      get all your questions answered and get coached in the hot
                      seat.
                    </Typography>
                  </div>
                  {/* <div style={{ display: "flex" }}>
                    <span style={{ marginRight: "4px" }}>
                      <CancelRoundedIcon
                        sx={{ fontSize: "18px", color: red[500] }}
                      />
                    </span>{" "}
                    One 45 min 1:1 Executive Coaching with Peter Kovacs
                  </div> */}
                </div>
              </Typography>
            </CardContent>
            <div className="pricing-amount-btn">
              <a
                dis
                className="enrollnow-btn-coming-soon"
                rel="noreferrer"
                href="https://cqs.circle.so/checkout/create-from-essence"
                target="_blank"
              >
                START TODAY
              </a>
            </div>
          </Card>
        </Grid>
        {/* <Grid className="pricing-card-grid-left" item xs={12} md={6} lg={6}>
          <Card className="pricing-card" sx={{ maxWidth: 345 }}>
            <CardHeader
              title={
                <h4>
                  MASTERCLASS + <br />
                  EXECUTIVE COACHING
                </h4>
              }
              sx={{
                fontWeight: "900 !important",
                fontFamily: "Lato",
                background: "#EDEDED",
                textAlign: "center",
              }}
            />
            <hr />
            <div style={{background:'#FFFEDD'}}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
              <Typography sx={{
                fontFamily: 'Lato',
                fontWeight:900,
                fontSize:'20px',
                color:'#FF0000'
              }}>
                VIP Package - Best value
              </Typography>
              </div>
              <div className="pricing-amount">
                <Typography sx={{ fontWeight: 700, fontSize: "70px" }}>
                  $897
                </Typography>
              </div>
              <CardContent className="pricing-card-content">
                <Typography
                  sx={{
                    color: "#14213d",
                    fontFamily: "montserrat",
                  }}
                  variant="body2"
                  color="text.secondary"
                >
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "20px",
                      fontWeight: 700,
                      color: "#3d3d47",
                      padding: "0px 0px 12px 0px",
                    }}
                  >
                    What's included?
                  </Typography>
                  <div
                    style={{
                      maxWidth: "220px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <span style={{ marginRight: "4px" }}>
                        <CheckCircleRoundedIcon
                          color="success"
                          sx={{ fontSize: "18px" }}
                        />
                      </span>{" "}
                      Masterclass
                    </div>
                    <div style={{ display: "flex" }}>
                      <span style={{ marginRight: "4px" }}>
                        <CheckCircleRoundedIcon
                          color="success"
                          sx={{ fontSize: "18px" }}
                        />
                      </span>{" "}
                      Community Access(for 1year)
                    </div>
                    <div style={{ display: "flex" }}>
                      <span style={{ marginRight: "4px" }}>
                        <CheckCircleRoundedIcon
                          color="success"
                          sx={{ fontSize: "18px" }}
                        />
                      </span>{" "}
                      Weekly office hours(for 1year)
                    </div>
                    <div style={{ display: "flex" }}>
                      <span style={{ marginRight: "4px" }}>
                        <CheckCircleRoundedIcon
                          color="success"
                          sx={{ fontSize: "18px" }}
                        />
                      </span>{" "}
                      One 45 min 1:1 Executive Coaching with Peter Kovacs
                    </div>
                  </div>
                </Typography>
              </CardContent>
              <div className="pricing-amount-btn">
                <a
                  dis
                  className="enrollnow-btn-coming-soon"
                  rel="noreferrer"
                  href="https://cqs.circle.so/checkout/create-from-essence-master-and-executive-coaching"
                  target="_blank"
                >
                  START TODAY
                </a>
              </div>
            </div>
          </Card>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default PricingCard;
