import ReactPlayer from "react-player";
import React from "react";
import { Box, styled, Typography, Grid, Button } from "@mui/material";
import HeaderMobileView from "./mobileViewComponents/HeaderMobileView";
import "./styles.css";

const Header = (props) => {

  let width = window.innerWidth;


  const BoxText = styled(Box)(({ theme }) => ({
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    width>480?(
    <div className="header-background">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "64px 30px 30px 30px",
        }}
        component="header"
      >
        <div className="header-logo">
          <img
            style={{ width: "100%" }}
            src={require("../assets/Create From Essence Logo_Transparent with box.png")}
            alt="amazon"
          />
        </div>
        <Grid container className={'header-grid-container-large'}>
          <Grid className="header-grid-section" item xs={12} md={12} lg={6}>
              {/* <VerticalCarousel slides={slides} interval={4000} /> */}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontFamily: "Bebas Neue",
                  fontSize:'48px',
                  paddingBottom:'30px',
                  lineHeight:'57px',
                  color: "#fff",
                }}
              >
                CREATE A SUCCESSFULL BUSINESS WITH IMPACT, MEANING, AND PURPOSE.
              </Typography>

              <Typography
                sx={{
                  paddingTop:'12px',
                  fontWeight: 400,
                  fontSize:'24px',
                  paddingBottom:'28px',
                  lineHeight:'28px',
                  fontFamily: "LATO",
                  color: "#fff",
                }}
              >
                Don’t just do what you think you are supposed to do or chase someone else’s dream.
              </Typography>

              <Typography
                sx={{
                  paddingTop:'12px',
                  fontWeight: 400,
                  fontSize:'24px',
                  lineHeight:'28px',
                  fontFamily: "LATO",
                  color: "#fff",
                }}
              >
                Create a business that is deeply resonant with who <br />you are. Create a life you love doing powerful work.
              </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className="header-video-box" style={{ color: "white", paddingTop: "42px" }}>
            <div className="header-video-section" style={{maxWidth:'630px', background:'#009CE0'}}>
              <ReactPlayer
                width='100%'
                height='100%'
                controls={true}
                url="https://www.dropbox.com/scl/fi/g847y63058grlyy4tgk3q/LP-Intro-Des.mp4?rlkey=ig45w6f3gkt34jdsukivbjm6i&dl=0"
              />
            </div>
            
          </Grid >
        </Grid>
        <Typography
          sx={{
            fontWeight: 700,
            color: "#3d3d47",
            margin:'28px 0px',
            padding: "12px 0px",
            textAlign: "center",
          }}
        >
          <span>
            <Button
              style={{
                border: "1px solid #f09711",
                fontWeight: 700,
                fontSize:'24px',
                lineHeight:'28.8px',
                fontFamily:'Lato',
                width:'338px',
                height:'72px',
                color:'#000000',
                background: "#f09711",
                borderRadius: "5px",
                padding: "0px 24px",
                alignItems: "center",
              }}
              onClick={()=>props.handleScrollSection('section2')}
            >
              Start today
            </Button>
          </span>
        </Typography>
      </div>
    </div>
    ):<HeaderMobileView handleScrollSection={props.handleScrollSection}/>
  );
};

export default Header;
