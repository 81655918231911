import React from 'react'
// import GetStarted from '../components/GetStarted';
import Header from '../components/Header';
import WhatsInIt from '../components/WhatsInIt';
import JustReflect from '../components/JustReflect';
import WhoIsItFor from '../components/WhoIsItFor';
import TasteOfCourse from '../components/TasteOfCourse';
import AboutPeterKovacs from '../components/AboutPerter';
import PricingCard from '../components/Pricingcard';
import Companies from '../components/Companies';
import FAQs from '../components/FAQs/FrequentlyAskedQues';
import TestimonailsNew from '../components/Testimonials';
import Curriculum from '../components/MasterclassCurriculum';

const Home = () => {

  const handleScrollSection = (selectedSection) => {
    const section = document.getElementById(selectedSection);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <>
      <Header id='section1' handleScrollSection={handleScrollSection}/>
      <Companies />
      <WhatsInIt />
      <WhoIsItFor />
      <JustReflect />
      <TasteOfCourse/>
      <TestimonailsNew />
      <AboutPeterKovacs />
      <PricingCard id='section2'/>
      <Curriculum />
      <FAQs />
    </>
  )
}

export default Home