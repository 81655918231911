import React from "react";
import { Typography, Grid } from "@mui/material";

import "../styles.css";

const WhatsInItMobileView = () => {
  return (
    <div className="whats-in-it-container">
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '80px',
          lineHeight:'96px',
          color: "#3d3d47",
          fontFamily: "Bebas Neue",
          textAlign: "center",
        }}
      >
        WHAT IS YOUR ESSENCE?
      </Typography>
      {/* <Typography
        variant="h4"
        component="h3"
        sx={{
          fontWeight: 700,
          color: "#3d3d47",
          fontFamily: "montserrat",
          padding: "12px 0px",
          textAlign: "center",
        }}
      >
        Explored in This Crafting Virtual Masterclass
      </Typography> */}
      <Grid container>
        <Grid item xs={12} lg={4}>
          <div>
            <img src={require('../../assets/peter_conference.png')} alt="signature" width='100%'/>
          </div>
        </Grid>
        <Grid item xs="12" lg="8">
        <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            >
              It was when my fourth startup became profitable, and I had more
              time and money to travel, that I realized that I was{" "}
              <span style={{ fontWeight: 700 }}>failing by succeeding.</span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            >
              I was succeeding at checking all the boxes{" "} <span style={{ fontWeight: 700 }}> I thought I was supposed to check: I </span>had a profitable tech startup in Silicon Valley, and I could afford all the things I wanted. 
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            >
              But I was <span style={{ fontWeight: 700 }}>failing at life - I</span> was miserable. I also learned I was not alone. 
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            >
              That is when I went on a <span style={{ fontWeight: 700 }}>Quest</span> to answer the following <span style={{ fontWeight: 700 }}>Question:</span> Is there more to life?
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            >
              One this journey, I learned about Essence. It changed my life.
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            >
              We all have an <span style={{ fontWeight: 700 }}>Essence - a natural way of being in the world that adds value and is effortless.</span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            >
              It’s <span style={{ fontWeight: 700 }}>not always about what’s bigger.</span><br />
              It’s about what’s <span style={{ fontWeight: 700 }}>resonant.</span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            > 
              Chasing what others want or what society tells you you are supposed to do is the recipe for unhappiness. <span style={{ fontWeight: 700 }}>That’s what I was doing.</span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            > 
              Once I learned about my essence, I looked for forms that were congruent with it. After many experiments and lessons, I became the executive coach I am today.
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            > 
              I now have <span style={{ fontWeight: 700 }}>a life I love doing powerful work.</span> There is space for all the things that matter to me: family, friends, travel, freedom, and impact. I change my client's lives while being fully present for my family. 
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            > 
              This is an <span style={{ fontWeight: 700 }}>invitation</span> to go on your own quest, to learn about your essence, and to create a business that is congruent with yours. <span style={{ fontWeight: 700 }}>And I will be your guide.</span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                color: "#3d3d47",
                padding: "12px 0px",
              }}
            > 
              If this resonates with you, keep scrolling.
            </Typography>
            <img src={require('../../assets/peter_signature.png')} alt="signature"/>
        </Grid>
      </Grid>
      

      {/*  */}
    </div>
  );
};

export default WhatsInItMobileView;
