import { Grid, Typography } from "@mui/material";
import React from "react";
import '../styles.css'

const WhoIsItForMobileView = () => {
  return (
    <div className="who-is-for-section" style={{ display: "flex", justifyContent: "center", alignItems:'center', flexDirection: 'column'}}>
      <Typography
          sx={{
            fontWeight: 400,
            fontSize:'80px',
            lineHeight:'96px',
            fontFamily:'Bebas Neue',
            textAlign: "center",
          }}
        >
          WHO IS THIS MASTERCLASS FOR ?
        </Typography>
        <Typography
        sx={{
          fontSize: "32px",
          fontFamily:'Lato',
          fontWeight: 400,
          textAlign: "center",
          lineHeight:"38px",
          letterSpacing:"0em",

        }}
      >
        Driven Leaders pursuing impact and growth
      </Typography>
        <Grid justifyContent='center' alignItems='center' marginTop="8px" container spacing={2}>
          <Grid className="who-is-it-for-grid-block" item xs={12} md={4} lg={4}>
            <div className="who-is-it-for-blocks">
            <Typography
          variant="h2"
          component="h1"
          sx={{
  
            color: "black",
            fontFamily:'Lato',
            textAlign: "center",
            fontWeight:"bold",
            fontSize:"26px",
            height:"56px",
           
          }}
        >
          Entrepreneurs 
        </Typography> 
            Get the guidance and help to < br/> avoid common mistakes
            </div>
          </Grid>
          <Grid className="who-is-it-for-grid-block" item xs={12} md={4} lg={4}>
            <div className="who-is-it-for-blocks">
            <Typography
              sx={{
                color: "black",
                fontFamily:'Lato',
                textAlign: "center",
                fontWeight:"bold",
                fontSize:"24px",
                height:"56px",
              }}
            >
              Post-Exit Founders
            </Typography> 
              Made a big exit but still < br/>feeling something is missing
            </div>
          </Grid>
          <Grid className="who-is-it-for-grid-block" item xs={12} md={4} lg={4}>
            <div className="who-is-it-for-blocks">
            <Typography
          variant="h2"
          component="h1"
          sx={{
            color: "black",
            fontFamily:'Lato',
            textAlign: "center",
            fontWeight:"bold",
            fontSize:"24px",
            height:"56px",
          }}
        >
          Seasoned Executives
        </Typography> 
             Levarage your experience < br/> to create a better life
            </div>
          </Grid>
          
        </Grid>
    </div>
  );
};

export default WhoIsItForMobileView;
