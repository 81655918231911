export const FAQandAnswers = [
  {
    question:'Who is this for?',
    answer: 'This masterclass is for everyone serious about creating a better life for themselves. Those not willing to settle for the status quo.  It’s for those who have am that is so aspirational that they know they will need to undergo a level of personal transformation to get there.'
  },
  {
    question:'Will there be networking?',
    answer:'Yes. There will be many opportunities to connect and network in the online community that is included with this Masterclass.  I’ll also teach you the best practices to create deep, meaningful connections and optimize for serendipity with your networks.'
  },
  {
    question:'I am already doing business training & Coaching',
    answer: 'That’s great!  It means you already see the value of investing in yourself.  It means you are already on the journey to create a better life for yourself and your family.  I’m proud of you for having started on this journey.The training and tools you’ll receive here will add to what you already have, empowering you to do even more.'
  },
  {
    question:'When does the masterclass start and finish?',
    answer:'The masterclass is self-paced, and you may take as long as you need to complete it. There is no time limit for completion.'
  },
  {
    question:'How long will I have access to the course?',
    answer: 'You will have lifetime access to Create from Essence when you purchase the masterclass. '
  },
  {
    question:'What skills will this masterclass teach me?',
    answer:'Yes. There will be many opportunities to connect and network in the online community that is included with this Masterclass.  I’ll also teach you the best practices to create deep, meaningful connections and optimize for serendipity with your networks.'
  },
  {
    question:'What if I am unhappy with the course?',
    answer:"Oh no! We don't want that! We offer a 15-day money-back guarantee on your purchase. If you are unhappy with the course for any reason, let us know within the first 15 days, and we will refund your payment."
  },
  // {
  //   question:'What do I do if I need help?',
  //   answer:"For questions about your billing or accessing the course materials, email help@createfromessence.com.If you have a question about how you put the course methods to work in your own business, we encourage you to ask those in the Create from Essence Community or at Peter’s Office Hours."
  // }
]