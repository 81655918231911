import React, { useState } from "react";
// mui
import { Typography, Box, Stack, Grid } from "@mui/material";
// carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import AlexImage from '../assets/alex-testimonial.jpeg'
import MariaImage from '../assets/maria_testimonial.png'
import JumokeImage from '../assets/jumoke_testimonial.png'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './styles.css'

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState();

  const testimonialData = [
    {
      id: 1,
      testimonials: [
        {
          testimonial:
            "Every leader is faced with blindspots that limit how much they believe their existing challenges and real barriers can be overcome. Peter's coaching, thought partnership and remarkable Rolodex have been crucial to enable me to overcome those on my path, pushing me ahead on my quest to improve the quality of life across an entire continent.",
          name: "Alex Tsado",
          post: "Co-Founder Ahura.AI",
          imageUrl: AlexImage
        },
        {
          testimonial:
            "I feel like without Peter, I wouldn’t be where I am today as a business owner. If you choose to work with Peter, be ready for transformation. The strategies implemented have enabled us to handle market shifts with agility and confidence, resulting in consistent growth.",
          name: "Jumoke Dada",
          post: "Founder Taeillo",
          imageUrl:JumokeImage
        },
        
      ],
    },
    {
      id: 2,
      testimonials: [
        {
          testimonial:
            "Thanks to Peter, I’ve experienced a boost in productivity because of my bias toward action, been bold enough to share more about my purpose, dreams, and ambitions, and then said yes",
          name: "Maria Laws",
          post: "VP L&D, Ahura.ai",
          imageUrl:MariaImage
        },
        {
          testimonial:
            "Every leader is faced with blindspots that limit how much they believe their existing challenges and real barriers can be overcome. Peter's coaching, thought partnership and remarkable Rolodex have been crucial to enable me to overcome those on my path, pushing me ahead on my quest to improve the quality of life across an entire continent.",
          name: "Alex Tsado",
          post: "Co-Founder Ahura.AI",
          imageUrl: AlexImage
        },
      ],
    },
  ];

  const CustomArrow = ({ onClick, direction }) => (
    <div
      onClick={onClick}
      className={`custom-arrow ${direction}`}
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 2,
      }}
    >
      {direction === 'left' ? <ArrowBackIosIcon fontSize="large"/> : <ArrowForwardIosIcon fontSize="large"/>}
    </div>
  );

  const renderTestmonials = testimonialData.map((data) => (
    <Grid container >
      <Grid
        style={{
          textAlign:'center',
          padding:'0px 12px'
        }}
        item
        xs={12}
        md={6}
        lg={6}
      >
        <div className="testimonial-text">
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: "32px",
              lineHeight: "38px",
              fontWeight: 500,
              marginBottom: "12px",
            }}
          >
            {data.testimonials[0].testimonial}
          </Typography>
        </div>
        <div className="testimonial-image-and-name-container">
          <div className="testimonail-image-container">
            <img style={{borderRadius:'52%'}} src={data.testimonials[0].imageUrl} alt="testimonial1"/>
          </div>
          <div className="testimonail-name-and-post">
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "28px",
                lineHeight: "33px",
                fontWeight: 700,
                paddingTop:'8px'
              }}
            >
              {data.testimonials[0].name}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                lineHeight: "33px",
                fontWeight: 300,
                color: "gray",
              }}
            >
              {data.testimonials[0].post}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={6} 
        style={{
          textAlign:'center',
          padding:'0px 12px'
        }}>
          <div className="testimonial-text">
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "32px",
                lineHeight: "38px",
                fontWeight: 500,
                marginBottom: "12px",
              }}
            >
              {data.testimonials[1].testimonial}
            </Typography>
          </div>
        <div className="testimonial-image-and-name-container">
          <div className="testimonail-image-container">
            <img style={{borderRadius:'52%'}} src={data.testimonials[1].imageUrl} alt="testimonial1"/>
          </div>
          <div className="testimonail-name-and-post">
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "28px",
                lineHeight: "33px",
                fontWeight: 700,
                paddingTop:'8px'
              }}
            >
              {data.testimonials[1].name}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "20px",
                lineHeight: "33px",
                fontWeight: 300,
                color: "gray",
              }}
            >
              {data.testimonials[1].post}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  ));

  const handleChange = (index) => {
    setCurrentIndex(index);
  };

  console.log('index',currentIndex)

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: 8,
        px: 2,
        display: { xs: "flex" },
      }}
    >
      <Box
        sx={{
          maxWidth: 1000,
          width: "100%",
        }}
      >
        <Carousel
          centerSlidePercentage={40}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <CustomArrow onClick={onClickHandler} direction="left" />
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <CustomArrow onClick={onClickHandler} direction="right" />
            )
          }
          thumbWidth={180}
          dynamicHeight={false}
          centerMode={false}
          showArrows={true}
          showThumbs={false}
          autoPlay={true}
          interval={10000}
          infiniteLoop={true}
          selectedItem={testimonialData[currentIndex]}
          onChange={handleChange}
          className="carousel-container"
        >
          {renderTestmonials}
        </Carousel>
      </Box>
    </Stack>
  );
};

export default Gallery;
