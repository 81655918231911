import { Typography } from '@mui/material'
import React from 'react'

const FooterTitle = ({ text }) => {
  return (
    <Typography
    sx={{
      fontWeight: '400',
      fontSize: '16px',
      textTransform: 'capitalize',
      lineHeight:'19.2px'
    }}
    >
      { text }
    </Typography>
  )
}

export default FooterTitle